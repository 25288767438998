import { useCallback, useEffect, useState } from "react";
import { Tooltip } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useIsMounted } from "hooks/useIsMounted";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { CheckIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  createOrUpdateTask,
  deleteTask,
  listTasksForParentCompany,
} from "requests/task";
import { getDealCompany } from "requests/deal";
import { listSiblingUsers } from "requests/user";
import confirmModal from "atoms/confirmModal";
import alert from "atoms/alert";
import filterAtom from "atoms/filter";
import userRoute from "atoms/userRoute";
import TaskDetail from "./Details";
import Filter from "components/shared/Filter";
import Pager from "components/shared/Pager";
import { TaskPriority, TaskPriorityMap } from "utils/enums";
import {
  FilterOptionsType,
  FilterType,
  SelectedFilterType,
  TaskFilter,
  TaskParsedType,
  TaskType,
} from "utils/types";
import { dateToLocalDate, iterableEnum, abbrString } from "utils/utils";

export default function TasksTable() {
  const [tasks, setTasks] = useState<TaskType[]>([]);
  const [selectedTask, setSelectedTask] = useState<TaskParsedType | null>(null);
  const [showDetails, setShowDetails] = useState(false);
  const navigate = useNavigate();
  const usrRoute = useRecoilValue(userRoute);

  const [recoilFilter, setRecoilFilter] =
    useRecoilState<FilterType>(filterAtom);

  const setAlerta = useSetRecoilState(alert);
  const [nextPage, setNextPage] = useState(1);
  const [count, setCount] = useState({
    total: 0,
    countPerPage: 1,
    currentPage: 1,
  });
  const [filterOptions, setFilterOptions] = useState<FilterOptionsType[]>([]);

  const setConfirmModal = useSetRecoilState(confirmModal);

  const isMounted = useIsMounted();

  const getOptionsData = useCallback(async () => {
    const usrs = await listSiblingUsers(); // Lawyers
    const priorities = iterableEnum(TaskPriority);

    const taskFilterOptions: FilterOptionsType[] = [
      {
        id: "assignedUserId",
        name: "Responsable",
        type: "checkbox",
        options: usrs.map((u) => ({
          value: u.id,
          label: `${u.firstName} ${u.lastName}`,
          checked: false,
        })),
      },
      {
        id: "priority",
        name: "Prioridad",
        type: "checkbox",
        options: priorities.map((p) => ({
          value: String(TaskPriorityMap[p]),
          label: p,
          checked: false,
        })),
      },
      {
        id: "completed",
        name: "Estado",
        type: "radio",
        options: [
          {
            value: 1,
            label: "Completada",
            checked: false,
          },
          {
            value: 0,
            label: "Pendiente",
            checked: false,
          },
        ],
      },
    ];

    setFilterOptions(taskFilterOptions);
  }, []);

  const getData = useCallback(async () => {
    const res = await listTasksForParentCompany({
      page: nextPage,
      includeAssignedUser: true,
      filter: recoilFilter.task,
    });

    if (res) {
      setTasks(res.data);
      setCount({
        total: res.totalCount,
        countPerPage: res.countPerPage,
        currentPage: res.currentPage,
      });
    }
  }, [nextPage, recoilFilter.task]);

  const onLinkClick = async (dealId: string) => {
    const c = await getDealCompany(dealId);
    if (c) {
      const companyData = `companyId=${c.id}&`;
      const isAdmin = usrRoute.navigation === "admin";

      navigate(
        `/${usrRoute.navigation}?${companyData}dealId=${dealId}${
          isAdmin ? `&clientState=deals` : ""
        }`
      );
    }
  };

  useEffect(() => {
    if (isMounted()) {
      getOptionsData();
    }
  }, [getOptionsData, isMounted]);

  useEffect(() => {
    if (isMounted()) {
      getData();
    }
  }, [getData, isMounted]);

  const onMarkComplete = useCallback(
    async (task: TaskType) => {
      await createOrUpdateTask({
        id: task.id,
        dealId: task.deal?.id,
        completed: true,
      });

      getData();
    },
    [getData]
  );

  const onDeleteTask = useCallback(
    async (id: string) => {
      const deleteSuccess = await deleteTask(id);
      if (deleteSuccess) {
        setAlerta({
          display: true,
          variant: "success",
          message: "Tarea borrada con éxito",
        });
      } else {
        setAlerta({
          display: false,
          variant: "error",
          message: "Hubo un error al borrar la tarea",
        });
      }

      getData();
    },
    [getData, setAlerta]
  );

  const setDeleteModal = (id: string, taskTitle: string) => {
    setConfirmModal({
      display: true,
      onConfirm: async () => {
        await onDeleteTask(id);
      },
      title: "Borrar tarea",
      variant: "alert",
      message: `¿Estas seguro(a) de querer borrar la tarea ${taskTitle} ?`,
    });
  };

  const setDetailsModal = (task: TaskType) => {
    setSelectedTask({ ...task, content: JSON.parse(task.content) });
    setShowDetails(true);
  };

  const onFilterChange = (filterList: SelectedFilterType[]) => {
    const filterObj = filterList.reduce((acc, curr) => {
      if (curr.type === "checkbox") {
        // @ts-ignore
        const currentValuesByCategory = acc[curr.categoryId] || [];

        const updatedValuesCategory = [...currentValuesByCategory, curr.value];

        return { ...acc, [curr.categoryId]: updatedValuesCategory };
      } else if (curr.type === "radio") {
        return { ...acc, [curr.categoryId]: curr.value };
      }

      return acc;
    }, {} as TaskFilter);
    setRecoilFilter({ ...recoilFilter, task: filterObj });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <h2 className="text-base font-semibold leading-7 text-gray-900 mb-3">
        Tareas
      </h2>
      <div className="mt-8 flow-root">
        <Filter
          initialFilters={recoilFilter.task}
          filterOptions={filterOptions}
          onFilterChange={onFilterChange}
        />
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300 mb-4">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                  >
                    Tarea
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Juicio
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Tribunal
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Estado
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Fecha Creación
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Fecha Plazo
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Abogado Responsable
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Prioridad
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    {/* Completar */}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    {/* Borrar */}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {tasks?.length ? (
                  tasks.map((task) => (
                    <tr key={`task-${task.id}`}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        <div className="text-indigo-600 hover:text-indigo-900 cursor-pointer">
                          <span onClick={() => setDetailsModal(task)}>
                            <Tooltip
                              hidden={task.title.length <= 24}
                              content={task.title}
                            >
                              {abbrString(task.title, 24)}
                            </Tooltip>
                          </span>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        <div
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                          onClick={() => onLinkClick(task.deal?.id || "")}
                        >
                          {task.deal?.dealname ? (
                            <Tooltip
                              hidden={task.deal?.dealname?.length <= 24}
                              content={task.deal?.dealname}
                            >
                              {abbrString(task.deal?.dealname, 24)}
                            </Tooltip>
                          ) : (
                            "Carátula no asignada"
                          )}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {task.deal?.court?.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {task.completed ? "Completada" : "Pendiente"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {dateToLocalDate(task.createdAt)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {dateToLocalDate(task.due)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {task.assignedUser?.firstName
                          ? `${task.assignedUser?.firstName} ${task.assignedUser?.lastName}`
                          : "No asignado"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {TaskPriority[task.priority]}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {task.completed ? (
                          <CheckIcon
                            className="block h-3 w-3"
                            aria-hidden="true"
                          />
                        ) : (
                          <button
                            onClick={() => onMarkComplete(task)}
                            className="relative inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                          >
                            <CheckIcon
                              className="block h-3 w-3"
                              aria-hidden="true"
                            />
                          </button>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        <button
                          onClick={() => setDeleteModal(task.id, task.title)}
                          className="relative inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                        >
                          <TrashIcon
                            className="block h-3 w-3"
                            aria-hidden="true"
                          />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <h6 className="pt-8">Tabla Vacía</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pager
              totalPages={Math.ceil(count.total / count.countPerPage)}
              current={count.currentPage}
              onChangePage={setNextPage}
            />
          </div>
        </div>
      </div>
      {selectedTask && (
        <TaskDetail
          open={showDetails}
          task={selectedTask}
          setShow={setShowDetails}
        />
      )}
    </div>
  );
}
