import { AlertType, ConfirmModalType, FilterType } from "./types";

export const apiUrl = process.env.REACT_APP_API_URL;

export const EDITTOR_HOLDER_ID = "editorjs";
export const EDITOR_HOLDER_ID_READ_ONLY = "editorjs-read-only";
export const EDITOR_UPDATE_HOLDER_ID = "editorjs-update";

export const defaultAlert: AlertType = {
  display: false,
  variant: "success",
  message: "Success!",
};

export const defaultConfirm: ConfirmModalType = {
  display: false,
  variant: "warning",
  message: "",
  onConfirm: async () => {},
  confirmText: "Confirmar",
  cancelText: "Cancelar",
};

export const defaultFilter: FilterType = {
  task: {
    completed: 0,
  },
};

// Procedimiento

export const Compentency = ["Civil"];

export const allowedFiles = [
  "jpeg",
  "jpg",
  "png",
  "doc",
  "docx",
  "txt",
  "pdf",
  "xlsx",
  "xls",
  "csv",
];

export const allowedMimeType = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/plain",
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv",
];

export const MAX_FILE_SIZE_MB = 10;
