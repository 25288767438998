import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useIsMounted } from "hooks/useIsMounted";
import userRoute from "atoms/userRoute";
import { Tooltip } from "flowbite-react";
import { listDeals } from "requests/deal";
import { Procedure, ProceduresTitles, ProcedureToDealstage } from "utils/enums";
import { DealType } from "utils/types";
import { abbrString } from "utils/utils";

export default function DealsTable({ companyId }: { companyId?: string }) {
  const [deals, setDeals] = useState<DealType[]>([]);
  const usrRoute = useRecoilValue(userRoute);
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  useEffect(() => {
    const getData = async () => {
      // TODO: Figure out how to set unauthorized Alert when reponse is 403
      const dls = await listDeals({
        requestRoute: usrRoute.request,
        companyId,
        includeCourt: true,
      });
      setDeals(dls);
    };
    if (isMounted()) {
      getData();
    }
  }, [companyId, isMounted]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 ">
      <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 ">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                  >
                    Demandado
                  </th>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                  >
                    Carátula / Juicio
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Rol
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Tribunal
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Procedimiento
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    Etapa
                  </th>
                  {!companyId && (
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Cliente
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {deals.length ? (
                  deals.map((deal) => (
                    <tr key={`dealtable-${deal.debtor}`}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        <div
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                          onClick={() => {
                            const companyData =
                              usrRoute.navigation === "admin"
                                ? `companyId=${deal.company?.id || companyId}&`
                                : "";
                            const isAdmin = usrRoute.navigation === "admin";
                            navigate(
                              `/${usrRoute.navigation}?${companyData}dealId=${
                                deal.id
                              }${isAdmin ? `&clientState=deals` : ""}`
                            );
                          }}
                        >
                          <Tooltip
                            hidden={deal.debtor.length <= 24}
                            content={deal.debtor}
                          >
                            {abbrString(deal.debtor, 24)}
                          </Tooltip>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {deal.dealname ? (
                          <Tooltip
                            hidden={deal.dealname.length <= 24}
                            content={deal.dealname}
                          >
                            {abbrString(deal.dealname, 24)}
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {deal.caseId || "-"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {deal.court?.name || "-"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {ProceduresTitles[Procedure[deal.procedure]]}
                      </td>
                      <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                        {
                          ProcedureToDealstage[Procedure[deal.procedure]][
                            deal.dealstage
                          ]
                        }
                      </td>
                      {!companyId && (
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                          {deal.company?.name}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <h6 className="pt-8">Tabla Vacía</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
